import { graphql, useStaticQuery } from 'gatsby';
import _map from 'lodash/map';

import localeFilter from '@lce/intl-util/src/localeFilter';
import { IImageTopPanelProps } from '@lce/gatsby-theme-multi-site/src/ui/PageComponents/Home/Panels/ImageTopPanel';
import { ILineObject } from '@lce/intl-types/src/ILineObject';

interface INodeImageTopPanelProps {
  ImageTopPanel: {
    nodes: IDatoBestValueNodeProps[];
  };
}

interface IDatoBestValueNodeProps {
  locale: string;
  headline?: ILineObject[];
  script?: ILineObject[];
  image?: {
    alt: string;
    url: string;
  };
}

const useHome = (locale: string): IImageTopPanelProps => {
  const data: INodeImageTopPanelProps = useStaticQuery(graphql`
    query UseImageTopPanelMsQuery {
      ImageTopPanel: allDatoCmsImageTopPanel {
        nodes {
          locale
          image {
            alt
            url
          }
          script {
            line
          }
          headline {
            line
          }
        }
      }
    }
  `);

  const removeLine = (array?: ILineObject[]): string[] => _map(array, object => object.line);

  const ImageTopPanel = localeFilter<IDatoBestValueNodeProps>(data.ImageTopPanel.nodes, locale);

  return {
    script: ImageTopPanel && removeLine(ImageTopPanel.script),
    headline: ImageTopPanel && removeLine(ImageTopPanel.headline),
    image: ImageTopPanel && ImageTopPanel.image,
  };
};

export default useHome;
