import { graphql, useStaticQuery } from 'gatsby';
import _map from 'lodash/map';

import localeFilter from '@lce/intl-util/src/localeFilter';
import { IBestValueProps } from '@lce/gatsby-theme-multi-site/src/ui/PageComponents/Home/Panels/BestValuePanel';
import { ILineObject } from '@lce/intl-types/src/ILineObject';

interface INodeBestValuePanelProps {
  bestValuePanel: {
    nodes: IDatoBestValueNodeProps[];
  };
}

interface IDatoBestValueNodeProps {
  locale: string;
  awardText?: ILineObject[];
  scriptText?: ILineObject[];
  legalText?: string;
}

const useBestValuePanel = (locale: string): IBestValueProps => {
  const data: INodeBestValuePanelProps = useStaticQuery(graphql`
    query UseBestValuePanelMsQuery {
      bestValuePanel: allDatoCmsBestValuePanel {
        nodes {
          locale
          awardText {
            line
          }
          scriptText {
            line
          }
          legalText
        }
      }
    }
  `);

  const removeLine = (array?: ILineObject[]): string[] => _map(array, object => object.line);

  const bestValuePanel = localeFilter<IDatoBestValueNodeProps>(data.bestValuePanel.nodes, locale);

  return {
    ...bestValuePanel,
    awardText: bestValuePanel && removeLine(bestValuePanel.awardText),
    scriptText: bestValuePanel && removeLine(bestValuePanel.scriptText),
  };
};

export default useBestValuePanel;
