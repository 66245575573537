import { graphql, useStaticQuery } from 'gatsby';
import { map as _map, sortBy as _sortBy } from 'lodash';
import { IDatoImage } from '@lce/intl-types/src/IDatoImage';
import { ILineObject } from '@lce/intl-types/src/ILineObject';
import { franchiseeFilterList } from '@lce/intl-util/src/FranchiseeFilter';
import { Direction } from '@lce/intl-types/src/IDirection';
import { IActiveBannerProps } from '@lce/intl-types/src/IActiveBannerProps';
import removeLine from '@lce/intl-util/src/removeLine';
import franchiseeDirection from '@lce/intl-util/src/FranchiseeDirection';

import useFranchisees from '../FranchiseeId/hooks/useFranchisees';
import { useSelectedFranchiseeId } from '../FranchiseeId/hooks/useSelectedFranchiseeId';

interface IDatoActiveBannerProps {
  activeBanners: {
    nodes: IDatoNodeActiveBannerProps[];
  };
}

export interface IDatoNodeActiveBannerProps {
  id: string;
  locale: string;
  endTime?: string;
  startTime?: string;
  customBannerComponent?: string;
  position: number;
  activeBanner: IDatoHomePageDefaultProps[];
}

interface IDatoHomePageDefaultProps {
  id: string;
  bannerName: string;
  image: IDatoImage;
  cents?: string;
  subCents?: string;
  dollars?: string;
  currencySymbol?: IDatoImage;
  subTitle: string;
  subTitle2: string;
  hours?: string;
  script?: string;
  superScript?: string;
  title: ILineObject[];
}

// eslint-disable-next-line max-lines-per-function
const useActiveBanner = (locale: string) => {
  const data: IDatoActiveBannerProps = useStaticQuery(graphql`
    query ActiveBannerMsQuery {
      activeBanners: allDatoCmsActiveBanner {
        nodes {
          id
          locale
          startTime
          endTime
          franchisee {
            name
            originalId
          }    
          activeBanner {
            bannerName
            cents
            dollars
            currencySymbol {
              alt
              url
            }
            image {
              alt
              url
            }
            script
            subCents
            subTitle
            subTitle2
            superScript
            hours
            title {
              line
            }
          }
          customBannerComponent
          position
        }
      }
    }
  `);
  const { franchiseeId: SelectedFranchiseeId } = useSelectedFranchiseeId(locale);
  const { franchisees } = useFranchisees(locale);

  const direction: Direction = franchiseeDirection(franchisees, SelectedFranchiseeId);

  const filteredOffsetBannersByFranchisee = franchiseeFilterList<IDatoNodeActiveBannerProps>(data.activeBanners.nodes, SelectedFranchiseeId, locale);

  let cleanedActiveBanners: IActiveBannerProps[] = [];

  _map(filteredOffsetBannersByFranchisee, offsetBanner => {
    if (offsetBanner.activeBanner[0]) {
      return (cleanedActiveBanners = [
        ...cleanedActiveBanners,
        {
          ...offsetBanner,
          activeBanner: {
            ...offsetBanner.activeBanner[0],
            title: removeLine(offsetBanner.activeBanner[0].title),
          },
          direction,
        },
      ]);
    }

    return (cleanedActiveBanners = [
      ...cleanedActiveBanners,
      {
        ...offsetBanner,
        direction,
      },
    ]);
  });

  const sortedActiveBanner = _sortBy(cleanedActiveBanners, (banners) => banners.position);

  return {
    ...sortedActiveBanner,
  };
};

export default useActiveBanner;
