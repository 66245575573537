import { IActiveBannerProps } from '@lce/intl-types/src/IActiveBannerProps';
import { map as _map, isEmpty as _isEmpty } from 'lodash';
import * as moment from 'moment';

import UseBanner from '../../Patterns/Banners/useBanner';

const dateTimeCheck = (banner: IActiveBannerProps) => {
  if (!banner.endTime && !banner.startTime) {
    return true;
  }
  if (banner.endTime && banner.startTime && moment().isAfter(banner.startTime) && moment().isBefore(banner.endTime)) {
    return true;
  }
  if (banner.endTime && !banner.startTime && moment().isBefore(banner.endTime)) {
    return true;
  }
  if (!banner.endTime && banner.startTime && moment().isAfter(banner.startTime)) {
    return true;
  }
  return false;
};

export const sanitizedList = (bannerData: IActiveBannerProps[]) => {
  const bannerList: any = [];
  let count = 0;
  _map(bannerData, (banner: IActiveBannerProps) => {
    if (
      (banner && banner.activeBanner && !_isEmpty(banner.activeBanner)) ||
      (banner && banner.customBannerComponent === 'MyTestComponent') ||
      (banner && banner.customBannerComponent && UseBanner[banner.customBannerComponent])
    ) {
      if (dateTimeCheck(banner)) {
        bannerList[count] = banner;
        count++;
      }
    }
  });
  return bannerList;
};
